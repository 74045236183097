// 鏆存紡 window.$
import "jquery";

import "./assets/scripts/common.js";
// import global css
import "./assets/styles/global.scss";

// import components
import "./components";

import "./assets/styles/WhirSwiper.css";
// import "swiper/dist/css/swiper.min.css";
// import Swiper from 'swiper/dist/js/swiper.min.js'

// import '@fancyapps/fancybox/dist/jquery.fancybox.min'
import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";

import "./assets/styles/animate.css";
